<template>
  <div
    class="fmea px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full">
        <TitleComponent title="FMEA Basis" size="large" />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
            ><img
              src="@/assets/RiskManagement/fmea_flow-min.png"
              width="728"
              height="809"
              alt=""
              title="SMS FMEA Flow Chart"
              class="img-responsive wp-image-11845"
          /></span>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <Paragraph>
            <p>
              An FMEA, or Failure Modes and Effects Analysis, is a systematic
              process hazards analysis approach that identifies failure modes
              (causes) that could either directly result in, or contribute
              significantly to an identified accident scenario. An FMEA is used
              to identify causes and effects as well as safeguards for accident
              scenarios.
            </p>
            <p>
              Each cause is evaluated for adequate design safety and potential
              effect on the system. A qualitative risk category is then assigned
              to each failure cause according to the guidelines found in MIL STD
              882E. This qualitative ranking is determined by considering both
              the severity and frequency of occurrence.
            </p>
            <p>
              Critical areas of the process are identified and studied to
              determine the possibility of a major incident. Management can then
              use this information to control the potential risk, and avoid the
              accident scenario. A block flow diagram of the FMEA process is
              given to the left:
            </p>
            <p>
              The following terms are typically used in the FMEA process and in
              the FMEA table (see downloadable example below):
            </p>
            <ul>
              <li>
                Line No. – consists of an “Operation/Item” number and a single
                letter identifying the specific “Failure Cause” (e.g., 1A, 1B,
                2A…).
              </li>
              <li>
                Operation/Item – the operation or item of concern in the
                scenario.
              </li>
              <li>Failure Mode – the potential problem stimulus.</li>
              <li>Failure Cause – events that cause the effects.</li>
              <li>
                Potential Effects – potential effects in the system or
                subsystem.
              </li>
              <li>
                Safeguards – those features of a system that will prevent the
                Failure Mode from occurring. Any deficiencies in design safety
                will be reflected in the Recommendation column.
              </li>
              <li>
                Hazard Category – an assessment of the hazard risk of the
                operation. In most analyses, we have used the MIL-STD-882E,
                <router-link style="color:#aa222c;" to="/risk-assessment-matrix"
                  >“Hazard Risk Assessment Matrix.”</router-link
                >
              </li>
              <li>
                Recommendations – recommended corrective actions. Deficiencies
                in design safety are corrected by implementing the
                recommendations in the Recommendation column.
              </li>
            </ul>
          </Paragraph>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
    </div>
    <Paragraph
      ><p>
        Safety Management Services, Inc. (SMS™) is in a unique position to
        assist you. Our engineers and scientists have an average of 15 years of
        experience in explosives risk management, testing, and test equipment.
        SMS personnel have a thorough understanding of energetic materials
        in-process testing, DOT testing, risk management, and sensitivity test
        equipment. &nbsp;Please contact us at 801-567-0456 or use the form below
        to request a quote or other information for the services we provide.
      </p>
    </Paragraph>

    <div class="flex flex-wrap -mx-3 mb-3">
      <div class="w-full md:w-1/2 px-3 mb-3 md:mb-0">
        <label
          class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
          for="grid-your-name"
        >
          Your Name
        </label>
        <input
          v-model="name"
          class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
          id="grid-your-name"
          type="text"
        />
      </div>
      <div class="w-full md:w-1/2 px-3">
        <label
          class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
          for="grid-company-name"
        >
          Company Name
        </label>
        <input
          v-model="companyname"
          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="grid-company-name"
          type="text"
        />
      </div>
    </div>
    <div class="flex flex-wrap -mx-3 mb-3">
      <div class="w-full md:w-1/2 px-3">
        <label
          class="blocktracking-wide text-gray-700 text-xs font-bold mb-2"
          for="grid-email"
        >
          Your Contact Info (Phone or Email)
        </label>
        <input
          v-model="contact"
          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="grid-contact"
          type="text"
        />
      </div>
      <div class="w-full md:w-1/2 px-3 mb-3 md:mb-0">
        <label
          class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
          for="grid-topic"
        >
          Topic
        </label>
        <div class="relative mb-3">
          <select
            v-model="topic"
            class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-topic"
          >
            <option>Facility Siting</option>
            <option>Risk Management Services</option>
            <option>Testing Services</option>
            <option>Test Equipment</option>
            <option>Training</option>
            <option>Shipping Containers</option>
            <option>FMEA</option>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap -mx-3 mb-2">
      <div class="w-full px-3 mb-3">
        <label
          class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
          for="grid-message"
        >
          Your Message: Please be specific
        </label>
        <textarea
          v-model="message"
          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="grid-message"
        >
        </textarea>
        <button
          @click="send"
          class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mt-8 rounded"
        >
          Send
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Failure Modes and Effects Analysis",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "Failure Modes and Effects Analysis (FMEA) is a hazards analysis approach used to identify and mitigate potential causes of process failure. Causes are assigned a risk category using severity and frequency criteria in MIL STD 882E.  SMS primarily uses FMEA when performing PHA work."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  },
  data: () => {
    return {
      loading: false,
      name: "",
      companyname: "",
      contact: "",
      topic: "FMEA",
      message: ""
    };
  },
  methods: {
    send() {
      let messageData = {
        name: this.name,
        companyname: this.companyname,
        contact: this.contact,
        topic: this.topic,
        subject: this.topic,
        message: this.message
      };

      let err = false;

      Object.keys(messageData).map(key => {
        if (!messageData[key].trim()) {
          err = true;
        }
      });

      if (err) {
        alert("Please populate all fields.");
        return false;
      }
      this.loading = true;

      fetch("/sendMail", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "no-cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(messageData) // body data type must match "Content-Type" header
      }).then(() => {
        this.loading = false;
        alert("Message Sent");
        this.topic = "";
        this.message = "";
      });
    }
  }
};
</script>
